<template>
  <div class="layout-header">
    <div class="title c-h" @click="$router.push('/dataV/index')">研学管理平台</div>
    <div class="config">
        <Row :gutter="10" type="flex" align="middle">
            <Col class="timer">
                {{timer}} 星期{{keys[week]}}
            </Col>
            <Col>
                <Icon class="c-h logout" type="ios-log-out" :size="28" color="#fff" @click="loginOut" />
            </Col>
        </Row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        frame: { required: true, type: String }
    },

    data() {
        const today = Date.new()
        return {
            keys: '日一二三四五六',
            week: today.getDay(),
            timer: Date.format(today, 'yyyy-MM-dd HH:mm:ss')
        }
    },
    computed: {
        ...mapState({
            page: state => state.ivew.config
        })
    },
    methods: {
        loginOut() {
            const _this = this
            this.$Modal.confirm({
                title: '退出',
                content: `是否退出${this.page.title}？`,
                async onOk() {
                   await  _this.$store.dispatch('user/getLogOut')
                    // _this.$store.commit('user/USER_INFO_LOGINOUT')
                    _this.$router.push({ path: '/login' })
                }
            })
        },
    },
    mounted() {
        setInterval(() => this.timer = Date.format(Date.new(), 'yyyy-MM-dd HH:mm:ss'), 1000)
    }
}
</script>

<style lang="less" scoped>
.layout-header {
    position: absolute;
    width: 100%;
    height: 60px;
    background: #5cadff;
    color: #fff;
    z-index: 9;
    .title {
        letter-spacing: 3px;
        // width: 800px;/
        font-size: 26px;
        line-height: 60px;
        // text-align: center;
        padding-left: 20px;
        font-weight: 600;
        // position: absolute;
        // left: calc(~"50% - 400px");
        // background-image: -webkit-linear-gradient(bottom, #ffffff, #333);
        // background-clip: text;
        // -webkit-text-fill-color: transparent;
    }
    .config {
        border-radius: 0px 0px 0px 10px;
        position: absolute;
        top: 5px;
        right: 10px;
        padding: 10px;
        .timer {
            font-size: 14px;
            line-height: 16px;
        }
        .logout {
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
</style>